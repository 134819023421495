import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type EnvelopeIconProps
 */
export type EnvelopeIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const EnvelopeIcon
 */
const EnvelopeIcon = (props: EnvelopeIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="13.476" ref={ ref } viewBox="0 0 17.967 13.476" width="17.967">
            <path d="M 16.283,0 H 1.684 A 1.685,1.685 0 0 0 0,1.684 v 10.107 a 1.685,1.685 0 0 0 1.684,1.684 h 14.6 a 1.685,1.685 0 0 0 1.684,-1.684 V 1.684 A 1.685,1.685 0 0 0 16.283,0 Z m -14.6,1.123 h 14.6 a 0.563,0.563 0 0 1 0.561,0.561 V 3.137 C 16.075,3.786 14.977,4.681 11.559,7.394 10.966,7.864 9.797,8.994 8.983,8.984 8.17,9 7,7.864 6.408,7.394 2.99,4.681 1.892,3.787 1.123,3.137 V 1.684 A 0.563,0.563 0 0 1 1.684,1.123 Z m 14.6,11.23 H 1.684 A 0.563,0.563 0 0 1 1.123,11.792 V 4.6 c 0.8,0.656 2.063,1.67 4.587,3.674 0.719,0.576 1.99,1.842 3.274,1.835 1.277,0.011 2.537,-1.246 3.274,-1.835 2.523,-2 3.786,-3.018 4.587,-3.674 v 7.194 a 0.563,0.563 0 0 1 -0.562,0.559 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(EnvelopeIcon);
