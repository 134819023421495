import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import AnchorButton from "@Js/Component/Button/AnchorButton";
import EnvelopeIcon from "@Js/Component/Icon/EnvelopeIcon";
import Heading from "@Js/Component/Heading";
import MobileIcon from "@Js/Component/Icon/MobileIcon";

import * as classNames from "@Css/Component/Block/WhatWeCanDoForYouBlock.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        contactSettings: {
            contact: {
                emailAddress: string;
                phoneNumber: string;
            };
        };
        otherSettings: {
            whatWeCanDoForYouImages: {
                whatWeCanDoForYouImageOne: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
                whatWeCanDoForYouImageTwo: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
        };
    };
};

/**
 * @type WhatWeCanDoForYouBlockProps
 */
export type WhatWeCanDoForYouBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const WhatWeCanDoForYouDoBlock
 */
const WhatWeCanDoForYouBlock = (props: WhatWeCanDoForYouBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const data: WordpressData = useStaticQuery(graphql`
        query {
            wordpress {
                contactSettings {
                    contact {
                        emailAddress
                        phoneNumber
                    }
                }
                otherSettings {
                    whatWeCanDoForYouImages {
                        whatWeCanDoForYouImageOne {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(aspectRatio: 1, quality: 100)
                                }
                            }
                        }
                        whatWeCanDoForYouImageTwo {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(aspectRatio: 1, quality: 100)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.whatWeCanDoForYouBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <div className={ classNames.images }>
                    <GatsbyImage
                        alt={ data.wordpress.otherSettings.whatWeCanDoForYouImages.whatWeCanDoForYouImageOne.altText }
                        className={ classNames.image }
                        image={
                            data.wordpress.otherSettings.whatWeCanDoForYouImages.whatWeCanDoForYouImageOne.localFile.childImageSharp.gatsbyImageData
                        }
                    />
                    <GatsbyImage
                        alt={ data.wordpress.otherSettings.whatWeCanDoForYouImages.whatWeCanDoForYouImageTwo.altText }
                        className={ classNames.image }
                        image={
                            data.wordpress.otherSettings.whatWeCanDoForYouImages.whatWeCanDoForYouImageTwo.localFile.childImageSharp.gatsbyImageData
                        }
                    />
                </div>
                <Heading>{ t("Curious About What We Can Do For You?") }</Heading>
                <div className={ classNames.buttons }>
                    <AnchorButton
                        className={ classNames.button }
                        href={ `mailto:${ data.wordpress.contactSettings.contact.emailAddress }` }
                    >
                        <EnvelopeIcon className={ classNames.icon } />
                        <span>{ t("Email us") }</span>
                    </AnchorButton>
                    <AnchorButton
                        backgroundProps={{ className: classNames.background }}
                        className={ classNames.button }
                        foregroundProps={{ className: classNames.foreground }}
                        href={ `tel:${ data.wordpress.contactSettings.contact.phoneNumber }` }
                    >
                        <MobileIcon className={ classNames.icon } />
                        <span>{ t("Call us") }</span>
                    </AnchorButton>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(WhatWeCanDoForYouBlock);
