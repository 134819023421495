import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type MobileIconProps
 */
export type MobileIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const MobileIcon
 */
const MobileIcon = (props: MobileIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="20.392" ref={ ref } viewBox="0 0 12.745 20.392" width="12.745">
            <path d="M 7.806,17.843 H 4.939 A 0.479,0.479 0 0 1 4.461,17.365 v -0.319 a 0.479,0.479 0 0 1 0.478,-0.478 h 2.867 a 0.479,0.479 0 0 1 0.478,0.478 v 0.319 A 0.479,0.479 0 0 1 7.806,17.843 Z M 12.745,1.912 V 18.48 a 1.912,1.912 0 0 1 -1.912,1.912 H 1.912 A 1.912,1.912 0 0 1 0,18.48 V 1.912 A 1.912,1.912 0 0 1 1.912,0 h 8.921 a 1.912,1.912 0 0 1 1.912,1.912 z m -1.274,0 A 0.639,0.639 0 0 0 10.834,1.275 H 1.912 A 0.639,0.639 0 0 0 1.275,1.912 V 18.48 a 0.639,0.639 0 0 0 0.637,0.637 h 8.921 A 0.639,0.639 0 0 0 11.47,18.48 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(MobileIcon);
